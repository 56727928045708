<template>
  <div>
    <div
      class="group bg-white hover:bg-primary-highlight transition ease-in-out duration-100 rounded shadow-md text-secondary p-5 flex flex-col relative"
    >
      <h2 class="text-lg uppercase">Enquiries</h2>
      <div class="relative flex items-baseline my-4">
        <div
          v-show="viewState == 'Loading'"
          class="absolute inset-0 bg-white transition ease-in-out duration-100 group-hover:bg-primary-highlight flex items-center justify-center"
        >
          <Spinner :color="'text-black'" :size="10" />
        </div>
        <div
          v-if="viewState == 'Error'"
          class="absolute inset-0 bg-white transition ease-in-out duration-100 group-hover:bg-primary-highlight flex flex-col justify-center"
        >
          <span class="leading-none font-bold text-2xl text-red-600 mb-2"
            >Error</span
          >
          <span class="leading-none text-lg text-red-600"
            >{{ error.status }}: {{ error.statusText }}</span
          >
        </div>
        <span class="text-5xl">{{ openCount }}</span>
        <span class="uppercase text-lg ml-2">Open</span>
        <span class="text-5xl mx-3">/</span>
        <span class="text-5xl">{{ closedCount }}</span>
        <span class="uppercase text-lg ml-2">Closed</span>
      </div>
      <router-link
        to="/prospects"
        class="absolute inset-0 hidden group-hover:block"
      ></router-link>
      <div class="flex items-center justify-end text-lg">
        <button
          @click="showingQuickAddPanel = true"
          class="z-10 border border-secondary hover:bg-secondary hover:text-primary-highlight transition-colors duration-100 ease-in-out pl-2 pr-3 py-1 rounded flex items-center"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path d="M12 5v14M5 12h14" />
          </svg>
          <span>Quick Add</span>
        </button>
      </div>
    </div>

    <portal to="overlay-outlet">
      <Panel
        :showing="showingQuickAddPanel"
        @close="showingQuickAddPanel = false"
        title="Quick Add Enquiry"
      >
        <QuickAddEnquiry @complete="enquiryAdded" />
      </Panel>
    </portal>
  </div>
</template>

<script>
const QuickAddEnquiry = () =>
  import("@/components/Dashboard/Enquiries/QuickAddEnquiry.vue");
const Panel = () => import("@/components/Panel.vue");
const Notification = () => import("@/components/Notification.vue");
const Spinner = () => import("@/components/Spinner.vue");

export default {
  components: {
    Panel,
    QuickAddEnquiry,
    Spinner,
  },
  data() {
    return {
      viewState: "Idle",
      openCount: 0,
      closedCount: 0,
      showingQuickAddPanel: false,
      error: null,
    };
  },
  async mounted() {
    await this.getEnquiries();
  },
  methods: {
    async getEnquiries() {
      this.viewState = "Loading";
      this.openCount = 0;
      this.closedCount = 0;
      try {
        let enquiriesResult = await this.SalesProspectService.getProspects();
        enquiriesResult.forEach((e) => {
          if (e.status == "Open") {
            this.openCount += 1;
          } else {
            this.closedCount += 1;
          }
        });
        this.viewState = "Idle";
      } catch (err) {
        console.error(err);
        this.error = err;
        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "Couldn't load sales enquiry info"
            );
          },
          {
            position: "top-right",
          }
        );
        this.viewState = "Error";
      }
    },
    async enquiryAdded() {
      this.showingQuickAddPanel = false;
      await this.getEnquiries();
    },
  },
};
</script>

<style  scoped>
.card-link {
  &:hover {
    text-decoration: underline !important;
    cursor: pointer;
  }
}
.enquiries-container {
  flex-direction: row;
}
@media screen and (min-width: 768px) and (max-width: 1225px) {
  .enquiries-container {
    flex-direction: column;
  }
}
</style>
